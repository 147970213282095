import { ref } from "vue";

const resultMessage = ref<string>();
const resultOK = ref<boolean>();

function setResult(message?: string, ok: boolean = true) {
  resultMessage.value = message;
  resultOK.value = ok;         
}

function clearResult() {
  setResult();
}

function errorFromUnknown(e: any): Error {
  if (e instanceof Error) return e;
  if (typeof e == "string") return new Error(e);
  if (typeof e?.message === 'string') return new Error(e.message);

  return new Error("Something went wrong. Please try again later.")
}

function setError(error: any) {
  setResult(errorMessageFromUnknown(error), false)
}

export function errorMessageFromUnknown(e: any): string {
  return errorFromUnknown(e).message;
}

export function useResultMessage() {
  return { 
    resultMessage,
    resultOK,
    clearResult,
    setResult,
    setError
  }
};
